import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent"
import { DashboardCardRowComponent } from "@components/admin/dashboards/DashboardCardRowComponent"
import { AdminTransactionFeeAndAveragesGetResponse } from "@reshopper/admin-client"
import { formatNumber } from "@utils/formatting"


export default function TransactionsFeeAndAveragesComponent(props: {
	transactionFeeAndAverages: AdminTransactionFeeAndAveragesGetResponse
}){
	return <DasboardCardComponent cardTitle={"LAST 30 DAYS"} sizeNumberDividedWithHundred={3} >
	<DashboardCardRowComponent
			text={"Fee"}
			value={formatNumber(Math.round(props.transactionFeeAndAverages.transactionFeesLastThirtyDaysSum / 100)) + " DKK"}
			secondValue={formatNumber(Math.round(props.transactionFeeAndAverages.transactionFeesLastThirtyDaysLastYearSum / 100)) + " DKK"} />
	<DashboardCardRowComponent
			text={"Average items"}
			value={formatNumber(+(props.transactionFeeAndAverages.averageAmountOfItemsOnTransactionLastThirtyDays).toFixed(2))}
			secondValue={formatNumber(+(props.transactionFeeAndAverages.averageAmountOfItemsOnTransactionLastThirtyDaysLastYear).toFixed(2))} />
	<DashboardCardRowComponent
			text={"Average order value"}
			value={formatNumber(Math.round(props.transactionFeeAndAverages.averageOrderValueOnTransactionLastThirtyDays / 100)) + " DKK"}
			secondValue={formatNumber(Math.round(props.transactionFeeAndAverages.averageOrderValueOnTransactionLastThirtyDaysLastYear / 100)) + " DKK"}
				isLastRow={true} />
</DasboardCardComponent>
}
