import { adminClient } from "@api/admin/AdminClient";
import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent";
import { DashboardComponent } from "@components/admin/dashboards/DashboardComponent";
import HandedInByShippingProviderComponent from "@components/admin/dashboards/shipping/HandedInByShippingProviderComponent";
import TransactionsByShippingStateComponent from "@components/admin/dashboards/shipping/TransactionsByShippingStateComponent";
import TransactionsFeeAndAveragesComponent from "@components/admin/dashboards/shipping/TransactionsFeeAndAveragesComponent";
import StackBarSectionComponent from "@components/admin/stats/StackBarSectionComponent";
import { GraphPoint } from "@components/admin/stats/StatsHelper";
import { Box } from "@material-ui/core";
import { AdminHandedInCountsGetResponse, AdminTransactionFeeAndAveragesGetResponse, AdminTransactionsByShippingStateCountsGetResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

export default function ShippingDashboardComponent() {
	const [lastRefreshUtc, setLastRefreshUtc] = useState<Date>(new Date());
	const [transactionsByShippingState, setTransactionsByShippingState] = useState<AdminTransactionsByShippingStateCountsGetResponse>();
	const [transactionsFeeAndAverages, setTransactionsFeeAndAverages] = useState<AdminTransactionFeeAndAveragesGetResponse>();
	const [handedInLastFiveWeeks, setHandedInLastFiveWeeks] = useState<AdminHandedInCountsGetResponse>();
	const keys = ["last year", "this year"];
	const colors = ["#0d47a1", "#62AED6"];

	async function fetchData() {
		fetchTransactionsByShippingSate();
		fetchTransactionsFeeAndAverages();
		fetchHandedInLastFiveWeeks();
	}

	async function fetchTransactionsByShippingSate() {
		var transactionsByShippingState = await adminClient().adminTransactionsByShippingStateCountsGet();
		setTransactionsByShippingState(transactionsByShippingState);
	}

	async function fetchTransactionsFeeAndAverages() {
		var transactionsFeeAndAverages = await adminClient().adminTransactionFeeAndAveragesGet();
		setTransactionsFeeAndAverages(transactionsFeeAndAverages);
	}

	async function fetchHandedInLastFiveWeeks() {
		var handedInLastThirtyDays = await adminClient().adminHandedInCountsGet();
		setHandedInLastFiveWeeks(handedInLastThirtyDays);
	}

	function generateGraphPoints() {
		if (!handedInLastFiveWeeks?.handedInYearOnYearLastFiveWeeksCounts) {
			return [] as GraphPoint[];
		}

		return handedInLastFiveWeeks?.handedInYearOnYearLastFiveWeeksCounts.map(stat => {
			return {
				x: stat.week,
				y1: stat.lastYearCount,
				y2: stat.thisYearCount
			} as GraphPoint
		});
	}

	return <DashboardComponent
		onRefresh={async () => {
			setLastRefreshUtc(new Date());
			fetchData();
		}}>

		<Box
			display="flex"
			flexDirection="row"
			flexWrap="Wrap"
		>
			{transactionsByShippingState && <TransactionsByShippingStateComponent transactionsByShippingState={transactionsByShippingState} />}
			<HandedInByShippingProviderComponent/>
			{transactionsFeeAndAverages && <TransactionsFeeAndAveragesComponent transactionFeeAndAverages={transactionsFeeAndAverages} />}
		</Box>

		<Box
			display="flex"
			flexDirection="row"
			flexWrap="Wrap"
		>

			<DasboardCardComponent cardTitle="HANDED IN WEEKLY">
				{handedInLastFiveWeeks && <StackBarSectionComponent
					stats={generateGraphPoints()}
					displayTicks={true}
					labelFontSize={35}
					tickFontSize={22}
					height={520}
					marginTop={44}
					marginLeft={0}
					marginBottom={34}
					barBackgroundColors={colors}
					textColor="#60453F"
					backgroundColor="#fafafa"
					legends={[
						{
							dataFrom: "keys",
							data: keys.map((id, index) => ({
								color: colors[index],
								id,
								label: id === "last year" ? new Date().getFullYear() - 1 : new Date().getFullYear()
							})),
							anchor: 'top-left',
							direction: 'row',
							itemWidth: 100,
							itemHeight: 50,
							translateY: -55,
							symbolSize: 20
						}
					]}
				/>}
			</DasboardCardComponent>
		</Box>

		<div >
			<footer  >
				{toDanishDateFormat(lastRefreshUtc)}
			</footer>
		</div>

	</DashboardComponent>
}