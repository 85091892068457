import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent"
import { DashboardCardRowComponent } from "@components/admin/dashboards/DashboardCardRowComponent"
import { AdminTransactionsByShippingStateCountsGetResponse } from "@reshopper/admin-client"
import { formatNumber } from "@utils/formatting"


export default function TransactionsByShippingStateComponent(props: {
	transactionsByShippingState: AdminTransactionsByShippingStateCountsGetResponse
}) {
	return <DasboardCardComponent cardTitle={"TODAY"} sizeNumberDividedWithHundred={3} >
		<DashboardCardRowComponent
			text={"Accepted"}
			value={"(" + formatNumber(props.transactionsByShippingState.acceptedTransactionsYesterdayCount) + ") " + formatNumber(props.transactionsByShippingState.acceptedTransactionsTodayCount)}
			secondValue={"(" + formatNumber(props.transactionsByShippingState.acceptedTransactionsYesterdayLastYearCount) + ") " + formatNumber(props.transactionsByShippingState.acceptedTransactionsLastYearCount)} />
		<DashboardCardRowComponent
			text={"Handed in"}
			value={"(" + formatNumber(props.transactionsByShippingState.handedInTransactionsYesterdayCount) + ") " + formatNumber(props.transactionsByShippingState.handedInTransactionsTodayCount)}
			secondValue={"(" + formatNumber(props.transactionsByShippingState.handedInTransactionsYesterdayLastYearCount) + ") " + formatNumber(props.transactionsByShippingState.handedInTransactionsLastYearCount)} />
		<DashboardCardRowComponent
			text={"Inquiries"}
			value={"(" + formatNumber(props.transactionsByShippingState.inquiriesTransactionsYesterdayCount) + ") " + formatNumber(props.transactionsByShippingState.inquiriesTransactionsTodayCount)}
			secondValue={"(" + formatNumber(props.transactionsByShippingState.inquiriesTransactionsYesterdayLastYearCount) + ") " + formatNumber(props.transactionsByShippingState.inquiriesTransactionsLastYearCount)}
			isLastRow={true} />
	</DasboardCardComponent>
}